import httpManager from "../axios/httpManager";

export function getAgentList(search, pageNum, pageSize) {
    let url = '/agent/agent_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function saveAgent  (agent) {
    return httpManager.post('/agent/save', agent);
}
export function updateAgent  (agent) {
    return httpManager.post('/agent/update', agent);
}

export function getAgentById  (id) {
    return httpManager.get('/agent/get_by_id?id=' + id + '&t=' + new Date().getTime())
}
export function givebackprofit  (id) {
    return httpManager.get('/agent/givebackprofit?id=' + id + '&t=' + new Date().getTime())
}
export function showprofit  (id) {
    return httpManager.get('/agent/agent_get_share_info?statisticPartnerId=' + id + '&t=' + new Date().getTime())
}


export function getAgentLogLogin  (agentId) {
    return httpManager.get('/partner/get_log_login?agentId=' + agentId + '&t=' + new Date().getTime());
}
export function agentUserRechargeList  (agentId, search) {
    return httpManager.post('/partner/partner_user_recharge_list?agentId=' + agentId, search)
}
export function agentrUserExchangeList  (agentId, search) {
    return httpManager.post('/partner/partner_user_exchange_list?agentId=' + agentId, search)
}
//
//
// export function delAgent  (id) {
//     return httpManager.get('/agent/del_agent?id=' + id + '&t=' + new Date().getTime());
// }
// export function delGeneralAgent  (id) {
//     return httpManager.get('/agent/del_general_agent?id=' + id + '&t=' + new Date().getTime());
// }
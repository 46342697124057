<template>
    <marquee class="marquee">
        <span v-html="lineMessage"></span>
    </marquee>
</template>

<script>
    import {getLineMessage} from "../network/userRequest";

    export default {
        name: "MarqueenComp",
        data() {
            return {
                lineMessage: '',
            }
        },
        methods: {

        },
        created() {
            getLineMessage().then(res => {
                if (res.data.success) {
                    let lines = res.data.data;
                    let cont = '';
                    lines.map(line => {
                        cont = cont + line.content +"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    })
                    this.lineMessage = cont
                }

            })
        },
        beforeDestroy() {

        }
    }
</script>

<style scoped>
    .marquee{
        background-color: #0e1410;
        border: 1px solid #00502f;
        border-radius: 3px;
        line-height: 28px;
        color: #fff60d;
    }
</style>
<template>
    <div class="menu-items">
        <router-link tag="div" to="/sports" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/sports.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/sports_inplay" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/inplay.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/sports_special" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/special.jpg" alt="">
        </router-link>

        <router-link :to="{path: '/casinohonor', query: {type: 1}}" tag="div" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/casino.jpg" alt="">
        </router-link>
        <router-link :to="{path: '/casinohonor', query: {type: 2}}" tag="div" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/slot.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/leisure/eospowerball5m" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/minigame.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/leisure/bet365_superleague" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/bet365.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/tgame365/tgame365baccarat" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/tgame.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/coupon" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/coupon.jpg" alt="">
        </router-link>

        <router-link tag="div" to="/culcheck" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/culcheck.jpg" alt="">
        </router-link>

        <router-link tag="div" to="/notice" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/notice.jpg" alt="">
        </router-link>

        <router-link tag="div" to="/event" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/event.jpg" alt="">
        </router-link>

        <router-link tag="div" to="/recmember" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/recmember.jpg" alt="">
        </router-link>

        <router-link tag="div" to="/message" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/message.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/sports_result" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/gameresult.jpg" alt="">
        </router-link>
        <router-link tag="div" to="/sports_betinfo" class="m-item">
            <img src="../assets/images/bg/mobile_menu/ptn/betinfo.jpg" alt="">
        </router-link>

<!--        <router-link tag="div" to="" class="m-item">-->
<!--        </router-link>-->

<!--        <router-link tag="div" to="/user_info" class="m-item">-->
<!--            <img src="../assets/images/bg/mobile_menu/ptn/userinfo.jpg" alt="">-->
<!--        </router-link>-->
<!--        <router-link tag="div" to="/board" class="m-item">-->
<!--            <img src="../assets/images/bg/mobile_menu/ptn/freeboard.jpg" alt="">-->
<!--        </router-link>-->





    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {weekpayback} from "../network/userRequest";

    export default {
        name: "MobileMenuItemsComp",
        methods:{
            weekpayback() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                weekpayback().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '주간 페이백 지급 성공',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            gotosportstv(){

            }
        }
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: start;
        flex-wrap: wrap;
        height: 430px;
        box-sizing: border-box;
        --margin-bottom: 50px;
    }

    .m-item {
        width: 24.2%;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        border-radius: 5px;
    }
    .m-item img{
        width: 100%;
    }


    .msports {
        background: url(../assets/images/bg/mobile_menu/ptn/sports.jpg) center center no-repeat;
        background-size: 100%;
    }
    .minplay {
        background: url(../assets/images/bg/mobile_menu/ptn/inplay.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mbet365 {
        background: url(../assets/images/bg/mobile_menu/ptn/bet365.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mgameresult {
        background: url(../assets/images/bg/mobile_menu/ptn/gameresult.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mminigame {
        background: url(../assets/images/bg/mobile_menu/ptn/minigame.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mcasino {
        background: url(../assets/images/bg/mobile_menu/ptn/casino.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mnotice {
        background: url(../assets/images/bg/mobile_menu/ptn/notice.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mevent {
        background: url(../assets/images/bg/mobile_menu/ptn/event.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mcoupon {
        background: url(../assets/images/bg/mobile_menu/ptn/coupon.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mtgame {
        background: url(../assets/images/bg/mobile_menu/ptn/tgame.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mcustomer {
        background: url(../assets/images/bg/mobile_menu/ptn/customer.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mrecharge {
        background: url(../assets/images/bg/mobile_menu/ptn/recharge.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mexchange {
        background: url(../assets/images/bg/mobile_menu/ptn/exchange.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mboard {
        background: url(../assets/images/bg/mobile_menu/ptn/fboard.jpg) center center no-repeat;
        background-size: 100%;
    }
    .msbetinfo {
        background: url(../assets/images/bg/mobile_menu/ptn/betinfo.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mculcheck {
        background: url(../assets/images/bg/mobile_menu/ptn/culcheck.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mmessage {
        background: url(../assets/images/bg/mobile_menu/ptn/message.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mrecmember {
        background: url(../assets/images/bg/mobile_menu/ptn/recmember.jpg) center center no-repeat;
        background-size: 100%;
    }
    .muserinfo {
        background: url(../assets/images/bg/mobile_menu/ptn/userinfo.jpg) center center no-repeat;
        background-size: 100%;
    }

</style>
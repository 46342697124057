<template>
  <div>
    <!--읽지 않은 메세지-->
    <message-comp></message-comp>
    <!--        <div class="footer">-->
    <!--            <div class="f1" style="">-->
    <!--                <img src="../assets/images/logo/ptn/logo02.png" alt="">-->
    <!--            </div>-->
    <!--            <div class="f2" style="">-->
    <!--                <p>By accessing, continuing to use or navigation throughout this site you accept that we will use certain borwser cookies</p>-->
    <!--                <p>to improve your customer experience  with us, Name only use cookies which will improve your experience with</p>-->
    <!--                <p>us amd will not interfere with your privacy</p>-->
    <!--            </div>-->
    <!--            <div class="f3" style="">-->
    <!--                <img src="../assets/images/logo/ptn/logo_gic.jpg" alt="" style="max-width: 80px;margin-right: 10px">-->
    <!--                <img src="../assets/images/logo/ptn/logo_ias.jpg" alt="" style="max-width: 80px">-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="footer">
      <!--                        <div class="footer_cont">-->
      <!--                            <div>-->
      <!--                                <img src="../assets/images/logo/ptn/logo02.png" alt="">-->
      <!--                            </div>-->
      <!--                            <div style="color: #24ae78;padding: 10px">-->
      <!--                                copyright(c) 2020-2023 All rights reserved-->
      <!--                            </div>-->
      <!--                        </div>-->
      <div class="footer_thirdparty" style="">


        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/praglive.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/bigtimegame.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/skywind.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/wm.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/microgamingca.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/1x2game.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/evolution.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/casino/dowin.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/7mojosgame.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/bbingame.png">
        </div>

        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/blueprintgame.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/logo/thirdparty/slot/evoplaygame.png">
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import MessageComp from "./MessageComp";

export default {
  name: "FootComp",
  components: {MessageComp},
  created() {
  }
}
</script>

<style scoped>
/*.footer {*/
/*    width: 100%;*/
/*    padding: 20px 320px 16px 320px;*/
/*    color: #fff9ff;*/
/*    background: var(--footBg);*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.footer img{*/
/*    width: 100%;*/
/*}*/

@media screen and (max-width: 1024px) {

}

.footer {
  width: 100%;
  padding: 0px 320px 16px 320px;
  color: #fff9ff;
  border-top: 1px solid #945d02;
  --background: var(--footBg);
}


.footer .footer_cont img {
  max-height: 100px;
}

.footer .footer_cont .footer_bottom .bitem p {
  padding: 3px 0;
  color: #a3a1a7;
}


.footer .footer_thirdparty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.footer .footer_thirdparty .th_item {
  width: 8%;
  text-align: center;
}

.footer .footer_thirdparty .th_item img {
  width: 90%;
}


</style>